$(document).ready(function () {

  var addNote = function(note, guid) {
    if (window.generalcode && window.generalcode.addNote) {
      window.generalcode.addNote(note, guid);
    }
    return false;
  };

  $("#addNoteButton").click(function (e) {
    e.preventDefault();

    // If the addNote function isn't defined or the button is disabled, don't do anything
    if (!window.generalcode || !window.generalcode.addNote || $(this).hasClass('disabled')) {
      return;
    }

    if (getSelectedGuids().length !== 1) {
      window.generalcode.openDialog("noteTooManyErrorDialog", {
        title: "Error",
        content: "Please select a single section. You can only add a note to one section at a time."
      });
      e.preventDefault();
      return;
    }
    var guid = getSelectedGuids()[0];

    var $notesArea = $("#notes-" + guid);
    if ($notesArea.length < 1) {
      window.generalcode.openDialog("noteNoChapterOnTocDialog", {
        title: "Error",
        content: "You cannot add this note here. Please navigate to " +
            "<a href='/" + guid + "'>this section of the code</a> to add your note."
      });
      e.preventDefault();
      return;
    }

    var $note = $("<div/>").addClass("note");
    $notesArea.append($note);

    addNote($note.get(0), guid);
  });

  $("body").on("click", ".noteContent a", function (e) {
    var $this = $(this);
    if ($this.attr("href")) {
      e.preventDefault();
      window.open($this.attr("href"));
    }
  });

});
